import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-taxa-delivery',
  templateUrl: './modal-taxa-delivery.component.html',
  styleUrls: ['./modal-taxa-delivery.component.scss']
})
export class ModalTaxaDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
