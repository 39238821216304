export const ESTABELECIMENTO = 'estabelecimento';
export const COLABORADOR = 'usuarios';
export const CATEGORIA = 'categoria';
export const SERVICOS_AGENDAVEIS = 'servicos';
export const PRODUTO = 'produto';
export const CLIENTE = 'v1/cliente';
export const AGENDA = 'agenda';
export const AGENDAMENTO = 'agendamento';


export const SIGNUP = 'v1/auth/signup';
export const EMPRESA = 'empresa';
export const EMPRESA_UPDATE_LOGO = 'empresa/logo';
export const EMPRESA_GET_LOGO = 'empresa/logo/$fileName$';
export const EMPRESA_FORMAS_PAGAMENTO = 'empresa/formas-pagamento';
export const EMPRESA_EXPEDIENTE = 'empresa/expediente';
export const EMPRESA_EXPEDIENTE_STATUS = 'empresa/expediente/$status$';

export const ESTEIRA_PEDIDOS = 'v1/food/esteira-pedido';
export const ESTEIRA_PEDIDOS_CHANGE_STATUS = 'v1/food/esteira-pedido/$id$/status';
export const ESTEIRA_PEDIDOS_CHANGE_STATUS_ARRAY = 'v1/food/esteira-pedido/change-status/list';
export const ESTEIRA_PEDIDOS_ESTEIRA_COUNT = 'v1/food/esteira-pedido/count';
export const ESTEIRA_PEDIDOS_ENTREGA = 'v1/food/esteira-pedido/entrega';
export const ESTEIRA_PEDIDOS_ENTREGA_FINALIZAR = 'v1/food/esteira-pedido/entrega/finalizar';
export const EXPEDIENTE_ENTREGADOR = 'v1/food/esteira-pedido/entregadores';


export const FOOD_PRODUTO = 'v1/food/produto';
export const FOOD_PRODUTO_COMPLEMENTOS_LIST = 'v1/food/produto-complementos/$id$/list';
export const FOOD_PRODUTO_COMPLEMENTOS = 'v1/food/produto-complementos';
export const FOOD_PRODUTO_UPLOAD_IMAGE = 'v1/food/produto/$id$/image';

export const FOOD_PRODUTO_COMPLEMENTOS_ITEM = 'v1/food/produto-complementos/item';

export const FOOD_CATEGORIA = 'v1/food/categorias';

export const ENTREGADOR = 'v1/entregador';


export const CONFIGS = 'v1/configuracoes/delivery';
