import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresaInsertEditComponent } from './empresa-insert-edit/empresa-insert-edit.component';
import { EmpresaMainComponent } from './empresa-main/empresa-main.component';
import {CdkDynamicTableModule, DgModalModule, DgPaginatorModule, DgTableModule} from "@datagrupo/dg-ng-util";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import { ModalTaxaDeliveryComponent } from './sub-components/modal-taxa-delivery/modal-taxa-delivery.component';



@NgModule({
  declarations: [
    EmpresaInsertEditComponent,
    EmpresaMainComponent,
    ModalTaxaDeliveryComponent
  ],
    imports: [
        CommonModule,
        DgTableModule,
        DgPaginatorModule,
        CdkDynamicTableModule,
        RouterModule,
        ReactiveFormsModule,
        NgxMaskModule,
        FormsModule,
        DgModalModule
    ]
})
export class EmpresaModule { }
