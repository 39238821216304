import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DgModalModule} from "@datagrupo/dg-ng-util";
import {DirectivesModule} from "../../../directives/directives.module";
import { ConfiguracoesMainComponent } from './configuracoes-main/configuracoes-main.component';
import { DeliveryConfiguracoesComponent } from './sub-pages/delivery-configuracoes/delivery-configuracoes.component';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    ConfiguracoesMainComponent,
    DeliveryConfiguracoesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DgModalModule,
    FormsModule,
    DirectivesModule,
    MatIconModule
  ]
})
export class ConfiguracoesModule { }
