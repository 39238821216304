import {Directive, HostListener, ElementRef, forwardRef, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

/**
 * Directive que apresenta o valor no padrão brasileiro mas mantem o mesmo no padrão americano.
 * Isso evitará conversões desnecessárias
 */

@Directive({
  selector: '[input-decimal]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AjusteDecimalDirective),
      multi: true,
    },
  ],
})
export class AjusteDecimalDirective implements ControlValueAccessor {
  onChange: any;
  onTouched: any;

  @Input() decimalNumber: '2' | '3' = '2'

  constructor(private el: ElementRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // função acionada quando o input recebe um valor externo
  writeValue(value: any): void {
    if (!value) value = '0';

    const decimalPlaces = Number(this.decimalNumber);

    if (typeof value === 'string') {
      value = parseFloat(value.replace(',', '.').replace(/[^\d.-]/g, ''));
    }

    if (isNaN(value)) {
      value = '0,'.padEnd(decimalPlaces, '0')
    }

    // Definir opções para formatar o valor
    const options = {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      style: "decimal",
      useGrouping: true
    };

    this.el.nativeElement.value = value.toLocaleString('pt-BR', options);
  }

  // função realizada ao escrever cada digito no input
  @HostListener('input', ['$event.target.value'])
  handleInput(value: string): void {
    const displayValue = this.formatToBrazilian(value);
    this.el.nativeElement.value = displayValue;

    const americanValue = this.ajusteMoneyBrToEua(displayValue);
    this.onChange?.(americanValue);
  }

  @HostListener('blur')
  handleBlur(): void {
    this.onTouched?.();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.el.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.el.nativeElement.removeAttribute('disabled');
    }
  }

  //--------------------------------

  // converte o numero para o formato brasileiro
  private formatToBrazilian(value: string | number): string {
    let val = String(value).replace(/\D/g, '')
    val = String(val).replace(/\./g, '')
    val = String(val).replace(',', '')
    if (!String(val).trim() || isNaN(Number(val))) {
      return this.decimalNumber == '3' ? '0,000' : '0,00';
    }

    if (this.decimalNumber == '3') {
      if (val.length < 4) {
        if (val.length == 1) val = '000' + val
        if (val.length == 2) val = '00' + val
        if (val.length == 3) val = '0' + val
      }
    } else {
      if (val.length < 3) {
        if (val.length == 1) val = '00' + val
        if (val.length == 2) val = '0' + val
      }
    }

    const int = Number(val.slice(0, val.length - Number(this.decimalNumber)))
    const decimal = val.slice(val.length - Number(this.decimalNumber))

    return Number(`${int}.${decimal}`)
      .toLocaleString("BRL", {maximumFractionDigits: Number(this.decimalNumber), minimumFractionDigits: Number(this.decimalNumber)});
  }

  // converte de volta o valor no padrão brasileiro para o americano
  ajusteMoneyBrToEua(value: string | number): number {
    let val = String(value).replace(/\./g, '')
    return Number(String(val).replace(',', '.'))
  }
}
