<div class="container mt-2 mb-2">
  <div class="row">
    <div class="col-12 d-inline-flex justify-content-end">
      <button class="btn btn-success" (click)="updateDelivery()">Salvar</button>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Delivery</h4>
        <p class="card-subtitle">Defina os parametros par pedidos por delivery</p>
      </div>
      <div class="card-body">
        <div class="row">
        </div>
        <div class="row mt-1">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <form [formGroup]="formDelivery">
              <label class="form-label">Raio de atuação</label>
              <div class="input-group mb-3">
                <input input-decimal decimalNumber="3" class="form-control" formControlName="raioEntrega">
                <span class="input-group-text" id="basic-addon2">KM</span>
              </div>
            </form>
            <div class="col-12">
              <div *ngIf="formDelivery.value.tipoTaxa == 'FIXA'" class="alert alert-info w-100 m-0" role="alert">
                <h4 class="alert-heading">Taxa fixa</h4>
                Crie uma taxa fixa, o valor será sempre o mesmo independente da distancia
              </div>
              <div *ngIf="formDelivery.value.tipoTaxa == 'KM'" class="alert alert-info w-100 m-0" role="alert">
                <h4 class="alert-heading">Cálculo por KM</h4>
                <p class="">Informe um valor para ser a base do quilômetro. A taxa será a distância * valor.</p>
                <p class="m-0">Exemplo:</p>
                <p class="m-0">Se o valor do quilômetro for "1,50" e a entrega for a "1.563KM", então o cálculo será</p>
                <p class="m-0">1.563*1,50 = 2.35</p>

                <hr>

                <h4 class="alert-heading">Taxa mínima</h4>
                <p>Informe a taxa mínima</p>
              </div>
              <div class="alert alert-info w-100 m-0" role="alert">
                <h4 class="alert-heading">Taxa por distância</h4>
                <p>Crie grupos de distancia para uma precificação por raio.</p>
                <p class="m-0">Exemplo:</p>
                <p class="m-0">Entre 0KM e 3KM = R$ 3,50</p>
                <p class="m-0">Entre 3.001KM e 5KM = R$ 5,50</p>
                <p class="m-0">Entre 5.001KM> = R$ 8,00</p>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
            <div class="col-12">
              <table class="table default-table">
                <thead>
                <tr>
                  <th>Inicio</th>
                  <th>Fim</th>
                  <th>Valor</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of listTaxaDistancia; let index = index">
                  <td>
                    <div class="input-group mb-3">
                      <input input-decimal decimalNumber="3" disabled [(ngModel)]="item.inicioKm" class="form-control">
                      <span class="input-group-text">KM</span>
                    </div>
                  </td>
                  <td>
                    <div class="input-group mb-3">
                      <input input-decimal decimalNumber="3" disabled [(ngModel)]="item.finalKm" class="form-control">
                      <span class="input-group-text">KM</span>
                    </div>
                  </td>
                  <td>
                    <div class="input-group mb-3">
                      <span class="input-group-text">R$</span>
                      <input input-decimal decimalNumber="2" disabled [(ngModel)]="item.valor" class="form-control">
                    </div>
                  </td>
                  <td>
                    <div class="d-inline-flex justify-content-end w-100">
<!--                      <button class="btn btn-secondary btn-sm m-1">-->
<!--                        <i class="bi bi-pencil-fill"></i>-->
<!--                      </button>-->
                      <button *ngIf="index == listTaxaDistancia.length-1" (click)="removeRaio(index)" class="btn btn-danger btn-sm m-1">
                        <i class="bi bi-trash3"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="listTaxaDistancia[listTaxaDistancia.length-1]?.finalKm != formDelivery.value?.raioEntrega">
                  <td colspan="100%">
                    <div class="d-inline-flex justify-content-center w-100">
                      <button class="btn btn-success btn-sm" (click)="openModalDelivery()">Adicionar nova taxa</button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dg-modal #modalTaxaDistancia title="Raio de entrega" size="md">
  <div modal-body>
    <form [formGroup]="formModalAddRaios">
      <div class="row">
        <div class="col-4">
          <label class="form-label">Início do raio</label>
          <div class="input-group">
            <input input-decimal decimalNumber="3" class="form-control" formControlName="inicioKm">
            <span class="input-group-text">KM</span>
          </div>
        </div>
        <div class="col-4">
          <label class="form-label">Final do raio</label>
          <div class="input-group">
            <input input-decimal decimalNumber="3" class="form-control" formControlName="finalKm">
            <span class="input-group-text">KM</span>
          </div>
        </div>
        <div class="col-4">
          <label class="form-label">Preço</label>
          <div class="input-group">
            <span class="input-group-text">R$</span>
            <input input-decimal decimalNumber="2" class="form-control" formControlName="valor">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div modal-footer>
    <button class="btn btn-success m-1" (click)="saveGrupoEntrega()">Salvar</button>
    <button class="btn btn-secondary m-1" (click)="modalClose()">Sair</button>
  </div>
</dg-modal>
