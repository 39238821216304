import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracoes-main',
  templateUrl: './configuracoes-main.component.html',
  styleUrls: ['./configuracoes-main.component.scss']
})
export class ConfiguracoesMainComponent implements OnInit {

  view: 'formas-pagamento' | 'delivery' | 'dias-trabalhados' = 'delivery';

  constructor() { }

  ngOnInit(): void {
  }

}
