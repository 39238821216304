import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpService} from "../../../../../services/http-service/http.service";
import {CONFIGS} from "../../../../../core/endpoints";
import Swal from "sweetalert2";
import {DgModalComponent} from "@datagrupo/dg-ng-util/lib/components/dg-modal/dg-modal/dg-modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'subpage-delivery-configuracoes',
  templateUrl: './delivery-configuracoes.component.html',
  styleUrls: ['./delivery-configuracoes.component.scss']
})
export class DeliveryConfiguracoesComponent implements OnInit {

  @ViewChild('modalTaxaDistancia') modalTaxas!: DgModalComponent;

  formDelivery = new FormGroup({
    raioEntrega: new FormControl(''),
    tipoTaxa: new FormControl(''),
    taxaMinima: new FormControl(''),
  });

  formModalAddRaios = new FormGroup({
    inicioKm: new FormControl({ value: '', disabled: true }, [Validators.required]),
    finalKm: new FormControl('', [Validators.required]),
    valor: new FormControl('', [Validators.required]),
  });

  listTaxaDistancia: { inicioKm: '0', finalKm: '', valor: '', edit: boolean }[] = [];

  constructor(private http: HttpService) {
    http.get(CONFIGS).subscribe(resp => {
      const raiosEntrega = JSON.parse(resp?.data?.raiosEntrega)

      if (Array.isArray(raiosEntrega)) {
        this.listTaxaDistancia = raiosEntrega.map(raio => ({...raio, edit: false }))
      }
      this.formDelivery.patchValue(resp?.data)
    })
  }

  ngOnInit(): void {
  }

  clickEditRaio(index: number) {
    this.listTaxaDistancia = this.listTaxaDistancia.map(raio => ({...raio, edit: false}))

    if (this.listTaxaDistancia[index]) {
      this.listTaxaDistancia[index].edit = true;
    }
  }

  updateDelivery() {
    if (this.formDelivery.invalid) {
      this.formDelivery.markAllAsTouched();
    }

    if (!this.validarListTaxaDistancia()) {
      return;
    }

    const data = this.formDelivery.value;
    data['raiosEntrega'] = this.listTaxaDistancia;

    this.http.put(CONFIGS, data).subscribe(resp => {
      console.log(resp)
    })
  }

  openModalDelivery() {
    const lastDistance = this.listTaxaDistancia[this.listTaxaDistancia.length-1];

    if (!!lastDistance) {
      this.formModalAddRaios.patchValue({ inicioKm: (Number(lastDistance.finalKm || 0) + 0.001) })
    } else {
      this.formModalAddRaios.patchValue({ inicioKm: 0 })
    }

    this.modalTaxas.open()
  }

  saveGrupoEntrega() {
    if (this.formModalAddRaios.invalid) {
      this.formModalAddRaios.markAllAsTouched();
      return;
    }

    this.listTaxaDistancia.push(this.formModalAddRaios.getRawValue());
    this.formModalAddRaios.reset('')
    this.modalTaxas.close()
  }

  removeRaio(index: number) {
    if (!this.listTaxaDistancia[index]) return;

    Swal.fire({
      icon: 'warning',
      title: 'Remover Raio?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Remover'
    }).then(conf => {
      if (conf.isConfirmed) {
        this.listTaxaDistancia.splice(index, 1)
      }
    })
  }

  validarListTaxaDistancia(): boolean {
    // Verifica se a lista está vazia
    if (this.listTaxaDistancia.length === 0) {
      Swal.fire({
        icon: 'error',
        title: "A lista de taxas de distância está vazia.",
        timer: 2000
      }).then()
      return false;
    }

    // Ordena a lista para garantir que os intervalos sejam analisados corretamente
    const listaOrdenada = this.listTaxaDistancia.sort((a, b) => parseFloat(a.inicioKm) - parseFloat(b.inicioKm));

    for (let i = 0; i < listaOrdenada.length; i++) {
      const atual = listaOrdenada[i];
      const proximo = listaOrdenada[i + 1];

      // Verifica se finalKm não está vazio
      if (!atual.finalKm) {
        Swal.fire({
          icon: 'error',
          title: `Intervalo finalKm está vazio no índice ${i}`,
          timer: 2000
        }).then()
        return false;
      }

      // Verifica se os intervalos são contíguos
      if (proximo && (parseFloat(atual.finalKm) + 0.001) !== parseFloat(proximo.inicioKm)) {
        Swal.fire({
          icon: 'error',
          title: `Intervalo inconsistente entre finalKm do índice ${i} e inicioKm do índice ${i + 1}`,
          timer: 2000
        }).then()
        return false;
      }
    }

    const raioEntrega = this.formDelivery.value.raioEntrega;

    // Verifica se o último finalKm é igual ao raioEntrega
    const ultimo = listaOrdenada[listaOrdenada.length - 1];
    if (parseFloat(ultimo.finalKm) !== parseFloat(raioEntrega)) {
      Swal.fire({
        icon: 'error',
        title: `O último finalKm (${ultimo.finalKm}) não é igual ao raioEntrega (${raioEntrega}).`,
        timer: 2000
      }).then()
      return false;
    }

    return true;
  }

  modalClose() {
    this.formModalAddRaios.reset('')
    this.modalTaxas.close()
  }
}
