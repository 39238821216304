import {Component, HostListener, OnInit} from '@angular/core';
import {CdkAbstractInsertEdit} from "@datagrupo/dg-crud";
import Swal from "sweetalert2";
import {SessionService} from "../../../services/session/session.service";
import {WebsocketService} from "../../../services/websocket/websocket.service";
import {HttpService} from "../../../services/http-service/http.service";
import {statusPedidoType} from "../../../enums/status-pedido.enum";
import {EMPRESA_EXPEDIENTE, ESTEIRA_PEDIDOS_ESTEIRA_COUNT} from "../../../core/endpoints";
import {Router} from "@angular/router";
import {alertDeletSingleIten} from "../../../helpers/swall.helpers";
import {DynamicGroupClass} from "@datagrupo/dg-ng-util";
import {generateActionRemove} from "../../../core/config/dg-ng-util/config-local-dg-table";

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit {

  numeroPedidosEmAnalise: number = 0;
  private notificationSound = new Audio('assets/sounds/notification-1.wav');

  constructor(
    public session: SessionService,
    private socketService: WebsocketService,
    private http: HttpService,
    private router: Router
  ) {
    socketService.socket.on('expediente-'+session.user.estabalecimento, resp => {
      this.session.expedienteAberto = resp;
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('init-esteira-pedido'))
      }, 1000)
    })
    socketService.socket.on(
      'esteira-produtos-' + session.user.estabalecimento,
      (resp: { status: statusPedidoType[], novoProduto: boolean }) => {

        if (resp.status.includes('EM_ANALISE')) {
          this.findNovosPedidos(resp.novoProduto)
        }
      })
    this.http.get(EMPRESA_EXPEDIENTE).subscribe(resp => {
      this.session.expedienteAberto = resp.data
      window.dispatchEvent(new CustomEvent('init-esteira-pedido'))
    })
  }

  ngOnInit(): void {
  }

  findNovosPedidos(novoPedido?: boolean) {
    this.http.get(ESTEIRA_PEDIDOS_ESTEIRA_COUNT, {params: {status: 'EM_ANALISE'}}).subscribe(resp => {
      this.numeroPedidosEmAnalise = resp.data;
      if (!!novoPedido) {
        this.notificationSound?.play().then()
      }
    })
  }

  /**#############################################################
   * ##### METODOS GLOBAIS
   #############################################################*/

  /**
   * Metodo de action da tabela
   * Esse realiza um routerLink para a rota recebida
   * @param ev
   */
  @HostListener('window:default-actions-dg-tables-router', ['$event'])
  defaultFunctionsDgTablesRouter(ev: CustomEvent<(string | number)[]>) {
    this.router.navigate(ev.detail).then(() => {
      window.scrollTo({ top: 0 });
    })
  }

  /**
   * Metodo de action da tabela
   * Esse realiza a exclusão de um ou mais registros
   * Verifique os dados em "<generateActionRemove>"
   * @param ev
   */
  @HostListener('window:default-actions-dg-tables-remove', ['$event'])
  defaultFunctionsDgTablesRemove(ev: CustomEvent<generateActionRemove>) {
    debugger
    const data = ev.detail;

    alertDeletSingleIten().then(confirm => {
      if (confirm.isConfirmed){
        if (confirm.isConfirmed){
          this.http.delete(data.url + '/' + data.id, {  }).subscribe(
            resp => {
              if (data.callback) {
                if (typeof data.callback == 'string') {
                  if (!data.callback) return;
                  DynamicGroupClass.dispacherFilter(data.callback)
                } else {
                  data.callback();
                }
              }
              Swal.fire({
                icon: 'success',
                title: data.messageAfter?.title || 'Registro removido',
                text: data.messageAfter?.text || 'O registro foi removido com sucesso!',
                timer: 3000
              }).then()
            }
          )
        }
      }
    })
  }

  /**
   * Exibição de mensagens de retorno da dg-crud
   * @param ev
   */
  @HostListener('window:crud-callback-message', ['$event'])
  crudCallbackMessage(ev: CustomEvent<CdkAbstractInsertEdit.crudCallbackMessage>) {
    const data = ev.detail;
    if (data.origin == 'dg-crud') {
      if (!!data.message?.status) {
        Swal.fire({
          icon: !!data.message?.status ? 'success' : 'warning',
          title: data.message?.title || '',
          text: data.message?.text || ''
        }).then()
      } else {
        Swal.fire({
          icon: !!data.message?.message?.status ? 'success' : 'warning',
          title: data.message?.message?.title || '',
          text: data.message?.message?.text || ''
        }).then()
      }
      return;
    } else {
      Swal.fire({
        icon: 'warning',
        title: data.message?.error?.message || '',
      }).then()
    }
    console.log(data)
  }
}
