<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <span
            class="nav-link"
            [class.active]="view == 'delivery'"
            (click)="view = 'delivery'"
          >
            Delivery
          </span>
        </li>
        <li class="nav-item">
          <span
            class="nav-link"
            [class.active]="view == 'dias-trabalhados'"
            (click)="view = 'dias-trabalhados'"
          >
            Dias de funcionamento
          </span>
        </li>
        <li class="nav-item">
          <span
            class="nav-link"
            [class.active]="view == 'formas-pagamento'"
            (click)="view = 'formas-pagamento'"
          >
            Formas pagamento
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>


<div class="container mt-4" [hidden]="view != 'delivery'">
  <subpage-delivery-configuracoes>
  </subpage-delivery-configuracoes>
</div>
