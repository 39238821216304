import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpService} from "../../../../../services/http-service/http.service";
import {SessionService} from "../../../../../services/session/session.service";
import {HttpHelpersService} from "../../../../../services/http-helpers/http-helpers.service";
import {EMPRESA, EMPRESA_GET_LOGO, EMPRESA_UPDATE_LOGO, FOOD_PRODUTO_UPLOAD_IMAGE} from "../../../../../core/endpoints";
import Swal from "sweetalert2";
import {replaceEndpointParams} from "../../../../../helpers/endpoints-helpers";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-empresa-insert-edit',
  templateUrl: './empresa-insert-edit.component.html',
  styleUrls: ['./empresa-insert-edit.component.scss']
})
export class EmpresaInsertEditComponent implements OnInit {

  formasPagamento: string[] = [];

  file: File | undefined;

  logoImg?: string;
  logoImgUrl?: string;

  formEmpresa = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    descricao: new FormControl(''),
    complemento: new FormControl(''),
    bairro: new FormControl(''),
    cidade: new FormControl(''),
    estado: new FormControl(''),
  });

  formHorariosFuncionamento = new FormGroup({
    domingoStatus: new FormControl(false),
    domingoAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    domingoFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    segundaStatus: new FormControl(false),
    segundaAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    segundaFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    tercaStatus: new FormControl(false),
    tercaAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    tercaFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    quartaStatus: new FormControl(false),
    quartaAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    quartaFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    quintaStatus: new FormControl(false),
    quintaAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    quintaFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    sextaStatus: new FormControl(false),
    sextaAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    sextaFechamento: new FormControl({value: '', disabled: true}, Validators.required),

    sabadoStatus: new FormControl(false),
    sabadoAbertura: new FormControl({value: '', disabled: true}, Validators.required),
    sabadoFechamento: new FormControl({value: '', disabled: true}, Validators.required),
  })

  formDelivery = new FormGroup({
    raioAtuacao: new FormControl(''),
    permitirTolerancia: new FormControl(''),
    taxaTolerancia: new FormControl(''),
    tipoTaxa: new FormControl(''),
  });

  listTaxaDistancia: {
    inicioKm: string,
    finalKm: string,
    valor: string,
  }[] = [
    {inicioKm: '0', finalKm: '', valor: ''}
  ];

  view: 'marca' | 'configuracoes' | 'layout' | 'formas-pagamento' | 'delivery' = 'marca';

  constructor(
    private http: HttpService,
    private session: SessionService,
    private httpHelper: HttpHelpersService,
  ) {
    this.formHorariosFuncionamento.controls['domingoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['domingoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['domingoFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['segundaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['segundaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['segundaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['tercaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['tercaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['tercaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quartaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quartaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quartaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['quintaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['quintaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['quintaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sextaStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sextaAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sextaFechamento'][!!resp ? 'enable' : 'disable']()
    })
    this.formHorariosFuncionamento.controls['sabadoStatus'].valueChanges.subscribe((resp: any) => {
      this.formHorariosFuncionamento.controls['sabadoAbertura'][!!resp ? 'enable' : 'disable']()
      this.formHorariosFuncionamento.controls['sabadoFechamento'][!!resp ? 'enable' : 'disable']()
    })

    this.formEmpresa.controls['cep'].valueChanges.subscribe((resp: any) => {
      this.httpHelper.getCep(resp, value => {
        this.formEmpresa.patchValue({
          logradouro: value.logradouro,
          bairro: value.bairro,
          cidade: value.localidade,
          estado: value.uf,
        })
      })
    })
    this.http.get(EMPRESA + '/' + session.user.estabalecimento).subscribe(
      resp => {
        const data = resp.data;

        this.formEmpresa.patchValue(resp.data)
        this.formasPagamento = (resp.data?.formasPagamento || '').split(',');
        this.logoImg = environment.apiUrl + replaceEndpointParams(EMPRESA_GET_LOGO, resp.data.logoImg);
        this.logoImgUrl = resp.data.logoImgUrl

        const dataPatchValue: { [key: string]: any } = {};

        if (!!data?.domingoAbertura && !!data?.domingoFechamento) {
          dataPatchValue['domingoStatus'] = true
          dataPatchValue['domingoAbertura'] = data?.domingoAbertura || '';
          dataPatchValue['domingoFechamento'] = data?.domingoFechamento || '';
        }

        if (!!data?.segundaAbertura && !!data?.segundaFechamento) {
          dataPatchValue['segundaStatus'] = 'true'
          dataPatchValue['segundaAbertura'] = data?.segundaAbertura || '';
          dataPatchValue['segundaFechamento'] = data?.segundaFechamento || '';
        }
        if (!!data?.tercaAbertura && !!data?.tercaFechamento) {
          dataPatchValue['tercaStatus'] = true
          dataPatchValue['tercaAbertura'] = data?.tercaAbertura || '';
          dataPatchValue['tercaFechamento'] = data?.tercaFechamento || '';
        }
        if (!!data?.quartaAbertura && !!data?.quartaFechamento) {
          dataPatchValue['quartaStatus'] = true
          dataPatchValue['quartaAbertura'] = data?.quartaAbertura || '';
          dataPatchValue['quartaFechamento'] = data?.quartaFechamento || '';
        }
        if (!!data?.quintaAbertura && !!data?.quintaFechamento) {
          dataPatchValue['quintaStatus'] = true
          dataPatchValue['quintaAbertura'] = data?.quintaAbertura || '';
          dataPatchValue['quintaFechamento'] = data?.quintaFechamento || '';
        }
        if (!!data?.sextaAbertura && !!data?.sextaFechamento) {
          dataPatchValue['sextaStatus'] = true
          dataPatchValue['sextaAbertura'] = data?.sextaAbertura || '';
          dataPatchValue['sextaFechamento'] = data?.sextaFechamento || '';
        }
        if (!!data?.sabadoAbertura && !!data?.sabadoFechamento) {
          dataPatchValue['sabadoStatus'] = true
          dataPatchValue['sabadoAbertura'] = data?.sabadoAbertura || '';
          dataPatchValue['sabadoFechamento'] = data?.sabadoFechamento || '';
        }

        this.formHorariosFuncionamento.patchValue(dataPatchValue)

      }
    )
  }

  ngOnInit(): void {}

  clickSelect(value: string) {
    const index = this.formasPagamento.findIndex(forma => forma == value);

    if (index >= 0) {
      this.formasPagamento.splice(index, 1)
    } else {
      this.formasPagamento.push(value)
    }
  }

  isSelected(value: string) {
    return this.formasPagamento.findIndex(forma => forma == value) >= 0;
  }

  save() {
    if (this.formEmpresa.invalid || this.formHorariosFuncionamento.invalid) {
      this.formEmpresa.markAllAsTouched();
      this.formHorariosFuncionamento.markAllAsTouched();
      return;
    }

    if (this.formasPagamento.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione ao menos uma forma de pagamento'
      }).then()
      return;
    }

    const {
      domingoStatus,
      segundaStatus,
      tercaStatus,
      quartaStatus,
      quintaStatus,
      sextaStatus,
      sabadoStatus,
      ...formHorarios
    } = this.formHorariosFuncionamento.value;

    const data = {
      id: this.session.user.estabalecimento,
      ...this.formEmpresa.value,
      formasPagamento: this.formasPagamento,
      domingoAbertura: domingoStatus ? formHorarios.domingoAbertura : '',
      domingoFechamento: domingoStatus ? formHorarios.domingoFechamento : '',
      segundaAbertura: segundaStatus ? formHorarios.segundaAbertura : '',
      segundaFechamento: segundaStatus ? formHorarios.segundaFechamento : '',
      tercaAbertura: tercaStatus ? formHorarios.tercaAbertura : '',
      tercaFechamento: tercaStatus ? formHorarios.tercaFechamento : '',
      quartaAbertura: quartaStatus ? formHorarios.quartaAbertura : '',
      quartaFechamento: quartaStatus ? formHorarios.quartaFechamento : '',
      quintaAbertura: quintaStatus ? formHorarios.quintaAbertura : '',
      quintaFechamento: quintaStatus ? formHorarios.quintaFechamento : '',
      sextaAbertura: sextaStatus ? formHorarios.sextaAbertura : '',
      sextaFechamento: sextaStatus ? formHorarios.sextaFechamento : '',
      sabadoAbertura: sabadoStatus ? formHorarios.sabadoAbertura : '',
      sabadoFechamento: sabadoStatus ? formHorarios.sabadoFechamento : '',
    }


    console.log(data);
    console.log(domingoStatus);
    console.log(formHorarios.domingoAbertura, formHorarios.domingoFechamento);
    debugger

    this.http.put(EMPRESA, data).subscribe(
      resp => {
        Swal.fire({
          icon: 'success',
          title: 'Formas de pagamento atualizadas'
        }).then()
      },
    )
  }

  uploadFile(event?: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (!file) return;

    const data = new FormData();
    data.set('file', file);

    this.http.post(EMPRESA_UPDATE_LOGO, data).subscribe(resp => {
      this.file = undefined;
      this.logoImgUrl = resp.data.logoImgUrl;
    })
  }

}
